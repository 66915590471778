import { inject } from '@angular/core';
import { ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { map, tap } from 'rxjs';
import { ProductStore } from './product.store';
import { AlternateProductsEntity } from './entities/AlternateProductsEntity';
import { withCachedEntityStore } from './WithCachedEntityStore';

export const AlternateProductsStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: AlternateProductsEntity) => p.id,
    load: (id: string) => {
      const api = inject(ProductApi);
      const productStore = inject(ProductStore);
      return api.getAlternateProducts(id).pipe(
        map((products) => ({ id, products })),
        tap({ next: (ap) => ap.products.forEach((p) => productStore.setData(p)) }),
      );
    },
    cacheSeconds: 60,
  }),
);
