import { inject } from '@angular/core';
import { ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { map } from 'rxjs';
import { ProductKeywordsEntity } from './entities/ProductKeywordsEntity';
import { withCachedEntityStore } from './WithCachedEntityStore';

export const ProductKeywordsStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductKeywordsEntity) => p.id,
    load: (id: string) =>
      inject(ProductApi)
        .getKeywords(id)
        .pipe(map((keywords) => ({ id, keywords }))),
    cacheSeconds: 60,
  }),
);
