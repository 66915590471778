import { inject } from '@angular/core';
import { ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { delay, map } from 'rxjs';
import { ProductSpecEntity } from './entities/ProductSpecEntity';
import { withCachedEntityStore } from './WithCachedEntityStore';

export const ProductSpecificationsStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductSpecEntity) => p.id,
    load: (id: string) =>
      inject(ProductApi)
        .getSpecs(id)
        .pipe(
          map((specifications) => ({ id, specifications })),
          // delay(5000),
        ),
    cacheSeconds: 60,
  }),
);
