import { computed, inject } from '@angular/core';
import { Product, ProductDetails } from '@idealsupply/products-api-angular-client';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import {
  AlternateProductsStore,
  ComplementaryProductsStore,
  ProductCrossReferencesStore,
  ProductDetailsStore,
  ProductKeywordsStore,
  ProductLinksStore,
  ProductSpecificationsStore,
  ProductStore,
} from '../global';
import { EntityRecord, withSelectableEntityStore } from '../global/WithCachedEntityStore';
import { ProductDownloadsStore } from '../global/product-downloads';
import { ProductDetailsLayout } from './ProductDetailsLayout';
import { AccountNumberRegistrationStore } from 'account-management-data';
import { CustomerService } from 'lib-customer';
import { toSignal } from '@angular/core/rxjs-interop';

export const ProductDetailsComponentStore = signalStore(
  withState({
    layout: ProductDetailsLayout.OneCol,
  }),
  withSelectableEntityStore('Product', {
    selectEntityById: (id: string) => inject(ProductDetailsStore).get(id),
    protected: true,
  }),
  withSelectableEntityStore('Specifications', {
    selectEntityById: (id: string) => inject(ProductSpecificationsStore).get(id),
    protected: true,
  }),
  withSelectableEntityStore('AlternateProducts', {
    selectEntityById: (id: string) => inject(AlternateProductsStore).get(id),
    protected: true,
  }),
  withSelectableEntityStore('ComplementaryProducts', {
    selectEntityById: (id: string) => inject(ComplementaryProductsStore).get(id),
    protected: true,
  }),
  withSelectableEntityStore('CrossReferences', {
    selectEntityById: (id: string) => inject(ProductCrossReferencesStore).get(id),
    protected: true,
  }),
  withSelectableEntityStore('Keywords', {
    selectEntityById: (id: string) => inject(ProductKeywordsStore).get(id),
    protected: true,
  }),
  withSelectableEntityStore('Links', {
    selectEntityById: (id: string) => inject(ProductLinksStore).get(id),
    protected: true,
  }),
  withSelectableEntityStore('DownloadLinks', {
    selectEntityById: (id: string) => inject(ProductDownloadsStore).get(id),
    protected: true,
  }),
  withComputed((store) => {
    const productStore = inject(ProductStore);
    const productRecord = computed<EntityRecord<ProductDetails>>(() => {
      const result = {
        loading: false,
        error: undefined,
      } as EntityRecord<ProductDetails>;

      const productId = store._selectedProductId();
      if (productId === undefined) {
        return result;
      }

      let prod: Product | undefined;

      if (productStore.isCacheValid(productId)) {
        prod = productStore.get(productId)().entity;
      }

      const productDetails = store._selectedProduct();
      Object.assign(result, productDetails);
      if (prod) {
        result.entity ??= {} as ProductDetails;
        Object.assign(result.entity, prod);
      }
      return result;
    });

    return {
      productRecord,
      product: computed(() => productRecord()?.entity),
      productError: computed(() => productRecord()?.error),
      productLoading: computed(() => productRecord()?.loading ?? false),
      specificationsRecord: computed(() => store._selectedSpecifications()),
      specifications: computed(() => store._selectedSpecifications()?.entity?.specifications),
      specificationsLoading: computed(() => store._selectedSpecifications()?.loading ?? false),
      alternateProductsRecord: computed(() => store._selectedAlternateProducts()),
      alternateProducts: computed(() => store._selectedAlternateProducts()?.entity?.products),
      alternateProductsLoading: computed(() => store._selectedAlternateProducts()?.loading ?? false),
      complementaryProductsRecord: computed(() => store._selectedComplementaryProducts()),
      complementaryProducts: computed(() => store._selectedComplementaryProducts()?.entity?.products),
      complementaryProductsLoading: computed(() => store._selectedComplementaryProducts()?.loading ?? false),
      crossReferencesRecord: computed(() => store._selectedCrossReferences()),
      crossReferences: computed(() => store._selectedCrossReferences()?.entity?.crossReferences),
      crossReferencesLoading: computed(() => store._selectedCrossReferences()?.loading ?? false),
      keywordsRecord: computed(() => store._selectedKeywords()),
      keywords: computed(() => store._selectedKeywords()?.entity?.keywords),
      keywordsLoading: computed(() => store._selectedKeywords()?.loading ?? false),
      linksRecord: computed(() => store._selectedLinks()),
      links: computed(() => store._selectedLinks()?.entity?.links),
      linksLoading: computed(() => store._selectedLinks()?.loading ?? false),
      downloadLinksRecord: computed(() => store._selectedDownloadLinks()),
      downloadLinks: computed(() => store._selectedDownloadLinks()?.entity?.links),
      downloadLinksLoading: computed(() => store._selectedDownloadLinks()?.loading ?? false),
      mainDownload: computed(() => store._selectedDownloadLinks()?.entity?.links[0]),
      mainDownloadLoading: computed(() => store._selectedDownloadLinks()?.loading ?? false),
    };
  }),
  withMethods((store) => {
    return {
      setSelectedProduct: (id: string) => {
        store._setProductId(id);
        store._setSpecificationsId(id);
        store._setAlternateProductsId(id);
        store._setComplementaryProductsId(id);
        store._setCrossReferencesId(id);
        store._setKeywordsId(id);
        store._setLinksId(id);
      },
      setLayout: (layout: ProductDetailsLayout) => {
        patchState(store, { layout });
      },
    };
  }),
);

// export type ProductDetailsState = {
//   selectedId: string | undefined;
//   _product: Product;
//   _productDetails: Signal<EntityRecord<ProductDetails>> | undefined;
// };

// export const ProductDetailsComponentStore0 = signalStore(
//   withState<ProductDetailsState>({
//     selectedId: undefined,
//     _product: {} as Product,
//     _productDetails: undefined,
//   }),

//   withComputed((store) => {
//     const getProductDetails = deepComputed<EntityRecord<ProductDetails>>(() => {
//       const product = store._product();
//       const productDetails = store._productDetails()?.() ?? ({} as EntityRecord<ProductDetails>);

//       return {
//         ...productDetails,
//         entity: {
//           ...(product as ProductDetails),
//           ...productDetails.entity,
//         },
//       };
//     });
//     const error = computed(() => getProductDetails()?.error);
//     const isLoading = computed(() => getProductDetails()?.loading ?? false);
//     return {
//       error,
//       isLoading,
//       productDetails: getProductDetails,
//     };
//   }),
//   withMethods((store) => {
//     const productStore = inject(ProductStore);
//     const detailsStore = inject(ProductDetailsStore);
//     return {
//       selectProduct: (productId: string) => {
//         if (productStore.isCacheValid(productId)) {
//           patchState(store, {
//             selectedId: productId,
//             _product: untracked(() => productStore.get(productId)()?.entity ?? ({} as Product)),
//           });
//         }
//         patchState(store, {
//           selectedId: productId,
//           _productDetails: untracked(() => detailsStore.get(productId)),
//         });
//       },
//     };
//   }),
// );
