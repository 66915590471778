import { inject } from '@angular/core';
import { ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { map } from 'rxjs';
import { ProductLinksEntity } from './entities/ProductLinksEntity';
import { withCachedEntityStore } from './WithCachedEntityStore';

export const ProductLinksStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductLinksEntity) => p.id,
    load: (id: string) =>
      inject(ProductApi)
        .getLinks(id)
        .pipe(map((links) => ({ id, links }))),
    cacheSeconds: 60,
  }),
);
