import { inject } from '@angular/core';
import { ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { map } from 'rxjs';
import { ProductCrossReferenceEntry, ProductCrossReferencesEntity } from './entities/ProductCrossReferencesEntity';
import { withCachedEntityStore } from './WithCachedEntityStore';
import { CustomerService } from 'lib-customer';

export const ProductCrossReferencesStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductCrossReferencesEntity) => p.id,
    load: (id: string) => {
      const customerService = inject(CustomerService);
      return inject(ProductApi)
        .getCrossReferences(id)
        .pipe(
          map((crossReferences) => ({
            id,
            crossReferences: customerService.availableCustomerNumbers.map(
              (cnData) =>
                ({
                  ...cnData,
                  crossReference: crossReferences.find((cr) => cr.customerNumber === cnData.customerNumber)?.crossReference ?? '',
                }) as ProductCrossReferenceEntry,
            ),
          })),
        );
    },
    cacheSeconds: 60,
  }),
);
