import { computed, inject, Injector, runInInjectionContext, Signal, untracked } from '@angular/core';
import {
  EmptyFeatureResult,
  patchState,
  SignalStoreFeature,
  signalStoreFeature,
  type,
  withMethods,
  withProps,
  withState,
} from '@ngrx/signals';
import { entityConfig, SelectEntityId, setEntity, updateEntity, withEntities } from '@ngrx/signals/entities';
import { Observable, tap } from 'rxjs';
import { Simplify } from 'type-fest';

type CachedEntity<TEntity, Tid extends string | number> = {
  id: Tid;
  lastUpdated: number;
  data?: TEntity;
  request?: Observable<TEntity>;
  requestError?: any;
};
// export type DeepNullable<T> = {
//   [P in keyof T]: T[P] extends object ? Simplify<DeepNullable<T[P]>> | null : T[P] | null;
// };
// export type DeepNullablePrimitives<T> = {
//   [P in keyof T]: T[P] extends object ? Simplify<DeepNullablePrimitives<T[P]>> : T[P] | null;
// };
export type WithCachedEntityStoreConfig<TEntity extends object, Tid extends string | number> = {
  selectId: SelectEntityId<TEntity>;
  load(id: Tid): Observable<TEntity>;
  cacheSeconds?: number;
  // createDefault(id: Tid): TEntity;
};

export type EntityRecord<TEntity> = {
  loading: boolean;
  error: any;
  entity: TEntity | undefined;
};

export function withCachedEntityStore<
  TEntity extends object,
  SelectId extends SelectEntityId<TEntity>,
  Tid extends string | number = ReturnType<SelectId>,
>(config: WithCachedEntityStoreConfig<TEntity, Tid>) {
  const entityCfg = entityConfig({
    entity: type<CachedEntity<TEntity, Tid>>() as any,
    selectId: (e) => e.id,
    collection: '_entities',
  });
  return signalStoreFeature(
    withEntities(entityCfg),
    withMethods((store) => {
      const injector = inject(Injector);

      const hasCacheEntry = (id: Tid) => {
        return store._entitiesIds().includes(id);
      };

      const isCacheValid = (id: Tid) => {
        return hasCacheEntry(id) && store._entitiesEntityMap()[id]?.lastUpdated + (config.cacheSeconds ?? 300) > Date.now();
      };

      const setData = (data: TEntity) => {
        patchState(
          store,
          updateEntity(
            {
              id: config.selectId(data),
              changes: {
                lastUpdated: Date.now(),
                data,
                request: undefined,
                requestError: undefined,
              },
            },
            entityCfg,
          ),
        );
      };

      const getComputed = (id: Tid) => {
        return runInInjectionContext(injector, () => {
          const entities = store._entitiesEntityMap();
          let entity = entities[id];
          if (!isCacheValid(id)) {
            if (!entity) {
              entity = {
                id,
                lastUpdated: 0,
                data: undefined,
                request: undefined,
                requestError: undefined,
              };
            }
            entity.requestError = undefined;
            entity.request = config.load(id).pipe(
              tap({
                next(value) {
                  entity.request = undefined;
                  entity.lastUpdated = Date.now();
                  entity.data = value;
                  entity.requestError = undefined;
                  patchState(store, setEntity(entity, entityCfg));
                },
                error(err) {
                  entity.request = undefined;
                  entity.lastUpdated = Date.now();
                  entity.requestError = err;
                  patchState(store, setEntity(entity, entityCfg));
                },
              }),
            );
            patchState(store, setEntity(entity, entityCfg));
            entity.request.subscribe();
          }
          return computed(() => {
            const e = store._entitiesEntityMap()[id];
            return {
              entity: e?.data,
              loading: !!e?.request,
              error: e?.requestError,
            } as EntityRecord<TEntity>;
          });
        });
      };
      return {
        get: getComputed,
        hasCacheEntry,
        isCacheValid,
        setData,
      };
    }),
  );
}

export type WithSelectableEntityStoreConfig<TEntity, Tid extends string | number> = {
  selectEntityById(id: Tid): Signal<TEntity> | undefined;
  protected?: boolean;
};

export interface WithProtectedSelectableEntityStoreConfig<TEntity, Tid extends string | number>
  extends WithSelectableEntityStoreConfig<TEntity, Tid> {
  protected: true;
}

type WithSelectableEntityStoreSelectByIdMethod<TName extends string, TId extends string | number> = {
  [K in `set${TName}Id`]: (id: TId) => void;
};
type WithSelectableEntityStoreProtectedSelectByIdMethod<TName extends string, TId extends string | number> = {
  [K in `_set${TName}Id`]: (id: TId) => void;
};

type WithSelectableEntityStoreSelectedIdProperty<TName extends string, TId extends string | number> = {
  [K in `selected${TName}Id`]: TId | undefined;
};

type WithSelectableEntityStoreProtectedSelectedIdProperty<TName extends string, TId extends string | number> = {
  [K in `_selected${TName}Id`]: TId | undefined;
};

type WithSelectableEntityStoreSelectedProperty<TName extends string, TEntity> = {
  [K in `selected${TName}`]: Signal<TEntity | undefined>;
};

type WithSelectableEntityStoreProtectedSelectedProperty<TName extends string, TEntity> = {
  [K in `_selected${TName}`]: Signal<TEntity | undefined>;
};

export function withSelectableEntityStore<
  TName extends string,
  TEntity,
  SelectId extends SelectEntityId<TEntity>,
  TId extends string | number = ReturnType<SelectId>,
>(
  name: TName,
  config: WithProtectedSelectableEntityStoreConfig<TEntity, TId>,
): SignalStoreFeature<
  EmptyFeatureResult,
  {
    state: WithSelectableEntityStoreProtectedSelectedIdProperty<TName, TId>;
    props: WithSelectableEntityStoreProtectedSelectedProperty<TName, TEntity>;
    methods: WithSelectableEntityStoreProtectedSelectByIdMethod<TName, TId>;
  }
>;

export function withSelectableEntityStore<
  TName extends string,
  TEntity,
  SelectId extends SelectEntityId<TEntity>,
  TId extends string | number = ReturnType<SelectId>,
>(
  name: TName,
  config: WithSelectableEntityStoreConfig<TEntity, TId>,
): SignalStoreFeature<
  EmptyFeatureResult,
  {
    state: WithSelectableEntityStoreSelectedIdProperty<TName, TId>;
    props: WithSelectableEntityStoreSelectedProperty<TName, TEntity>;
    methods: WithSelectableEntityStoreSelectByIdMethod<TName, TId>;
  }
>;

export function withSelectableEntityStore<
  TName extends string,
  TEntity,
  SelectId extends SelectEntityId<TEntity>,
  TId extends string | number = ReturnType<SelectId>,
>(
  name: TName,
  config: WithSelectableEntityStoreConfig<TEntity, TId>,
): SignalStoreFeature<
  EmptyFeatureResult,
  {
    state: WithSelectableEntityStoreSelectedIdProperty<TName, TId> | WithSelectableEntityStoreProtectedSelectedIdProperty<TName, TId>;
    props: WithSelectableEntityStoreSelectedProperty<TName, TEntity> | WithSelectableEntityStoreProtectedSelectedProperty<TName, TEntity>;
    methods: WithSelectableEntityStoreSelectByIdMethod<TName, TId> | WithSelectableEntityStoreProtectedSelectByIdMethod<TName, TId>;
  }
> {
  const prot = config.protected ? '_' : '';
  const selectedIdProperty = `${prot}selected${name}Id`;
  const selectedProperty = `${prot}selected${name}`;
  const setMethod = `${prot}set${name}Id`;

  return signalStoreFeature(
    withState({
      [selectedIdProperty]: undefined,
    } as WithSelectableEntityStoreSelectedIdProperty<TName, TId> | WithSelectableEntityStoreProtectedSelectedIdProperty<TName, TId>),
    withMethods((store) => {
      return {
        [setMethod]: (id: TId) =>
          patchState(store, {
            [selectedIdProperty]: id,
          } as any),
      } as WithSelectableEntityStoreSelectByIdMethod<TName, TId> | WithSelectableEntityStoreProtectedSelectByIdMethod<TName, TId>;
    }),
    withProps((store) => {
      const injector = inject(Injector);
      const selectedSignal = computed(() => {
        const selectedId = (store as any)[selectedIdProperty]() as TId | undefined;
        if (selectedId === undefined) {
          return undefined;
        }
        return runInInjectionContext(injector, () => {
          const entitySignal = untracked(() => config.selectEntityById(selectedId));
          return entitySignal;
        });
      });

      const selected = computed(() => selectedSignal()?.() as TEntity | undefined);
      return {
        [selectedProperty]: selected,
      } as WithSelectableEntityStoreSelectedProperty<TName, TEntity> | WithSelectableEntityStoreProtectedSelectedProperty<TName, TEntity>;
    }),
  );
}

/*



export function WithSelectableEntityStore<
  TName extends string,
  // Input extends SignalStoreFeatureResult,
  TEntity,
  SelectId extends SelectEntityId<TEntity>,
  Tid extends string | number = ReturnType<SelectId>,
>(name: TName, config: WithSelectableEntityStoreConfig<TEntity, Tid>) {
  return signalStoreFeature(
    withState({
      [`selected${name}Id`]: undefined,
    } as WithSelectableEntityStoreSelectedIdProperty<TName, Tid>),
    withMethods((store) => {
      return {
        [`set${name}Id`]: (id: Tid) =>
          patchState(store, {
            [`selected${name}Id`]: id,
          } as any),
      } as WithSelectableEntityStoreMethods<TName, Tid, TEntity>;
    }),
    withProps((store) => {
      const injector = inject(Injector);
      const selectedSignal = computed(() => {
        const selectedId = (store as any)[`selected${name}Id`]() as Tid | undefined;
        if (selectedId === undefined) {
          return undefined;
        }
        return runInInjectionContext(injector, () => {
          const entitySignal = untracked(() => config.selectEntityById(selectedId));
          return entitySignal;
        });
      });

      const selected = computed(() => selectedSignal()?.() as TEntity | undefined);

      return {
        [`selected${name}`]: selected,
      } as WithSelectableEntityStoreSelectedProperty<TName, TEntity>;
    }),
    // withComputed((store) => {
    //   const injector = inject(Injector);
    //   const selected = computed(() => {
    //     const selectedId = (store as any)[`selected${name}Id`]() as Tid | undefined;
    //     if (selectedId === undefined) {
    //       return undefined;
    //     }
    //     return runInInjectionContext(injector, () => {
    //       const entitySignal = untracked(() => config.selectEntityById(selectedId));
    //       return entitySignal ? entitySignal() : undefined;
    //     });
    //   });
    //   return {
    //     [`selected${name}`]: selected,
    //   } as WithSelectableEntityStoreSelectProperty<TName, TEntity>;
    // }),
  );
}
*/
