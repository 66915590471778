import { inject } from '@angular/core';
import { ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { map } from 'rxjs';
import { ProductDownloadLinksEntity } from './entities/ProductDownloadLinksEntity';
import { withCachedEntityStore } from './WithCachedEntityStore';

export const ProductDownloadsStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductDownloadLinksEntity) => p.id,
    load: (id: string) =>
      inject(ProductApi)
        .getDownloadLinks(id)
        .pipe(map((links) => ({ id, links }))),
    cacheSeconds: 60,
  }),
);
